/* RatingBox.module.css */
.boxContainer {
    display: flex;
    padding: 1px;
}

.box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    /* transition: all 0.3s ease-in-out; */
}

.unselected {
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected {
    background-color: #007ee5;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.header {
    font-size: 20px;
    color: #333;
    font-weight: 600;
    margin-bottom: 1px;
    margin-top: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.headerError {
    color: #db3e00;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(219, 62, 0, 0.4);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(219, 62, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(219, 62, 0, 0);
    }
}

.notificationBox {
    margin-top: 5px;
    background-color: #feefc3;
    color: #333;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    display: none;
    border: 1px solid #fed7b0;
}

.notificationBoxShow {
    display: block;
}