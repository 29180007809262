.submitButton {
    background-color: #0052cc;

    color: #ffffff;
    font-size: 18px;
    padding: 10px 20px;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}



.submitButton:hover {
    background-color: #003ea0;
}

.surveyContainer {
    margin-left: 55px;
    margin-top: 20px;
    margin-bottom: 120px;
}

/* .header {
    font-size: 24px;
    color: #333;
    font-weight: 100;
    margin-bottom: 1px;
    margin-top: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
} */

.header {
    font-size: 2em;
    text-align: center;
    color: #f2f2f2;
    padding: 0px;
    max-width: 400px;
    background-color: #007ee5;
    /* border: 1px solid #e0e0e0; */
    border: 1px solid #f2f2f2;
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
    transition: all 0.3s ease;
}

.header:hover {
    background-color: #007ee5;
    color: #f2f2f2;
    border: 1px solid #f2f2f2;
    /* background-color: #e0e0e0; */
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
}